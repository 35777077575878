import { type Analytics } from "../services/analytics";

export const renderPopup = (
  contents: string,
  closeBtnId?: string,
  backdropId?: string,
  analytics?: Analytics
): HTMLDivElement => {
  const container = document.createElement("div");
  container.innerHTML = contents;
  document.body.appendChild(container);
  analytics?.onOpen();

  if (closeBtnId) {
    const closeBtn = document.querySelectorAll(`#${closeBtnId}`);
    closeBtn.forEach((element) => {
      element.addEventListener("click", () => {
        analytics?.onClose();
        container.remove();
      });
    });
  }

  if (backdropId) {
    const backdrop = document.querySelector(`#${backdropId}`);
    backdrop?.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }

  return container;
};

export const setElement = (linkId: string, text: string, href?: string) => {
  const element = document.querySelector(`#${linkId}`);
  element && (element.innerHTML = text);
  if (!href) return;
  element?.setAttribute("href", href);
};

export const setButton = (
  buttonId: string,
  text: string,
  onClick: () => void
) => {
  const button = document.querySelector(`#${buttonId}`);
  button && (button.innerHTML = text);
  button?.addEventListener("click", onClick);
};
