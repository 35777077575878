import { GF_IDENTIFIER, GF_SESSION_ID } from "../utils/constants";
import { type AnalyticsMethods } from "./analytics";

declare const EVENTS_API_URL: string;

const getIdentifer = async () => {
    let identifier = localStorage.getItem(GF_IDENTIFIER);
    if(!identifier){
        identifier = crypto.randomUUID()
        localStorage.setItem(GF_IDENTIFIER, identifier);
    }
    return identifier;
}

const getSessionId = async () => {
    let sessionId = sessionStorage.getItem(GF_SESSION_ID);
    if(!sessionId){
        sessionId = crypto.randomUUID()
        sessionStorage.setItem(GF_SESSION_ID, sessionId);
    }
    return sessionId;
}

const callEventsApi = async (partner:string, eventType: AnalyticsMethods, eventData?: object) => {
    const identifier = await getIdentifer();
    const sessionId = await getSessionId();
    const body:any = {
        partner,
        eventType,
        identifier,
        sessionId,
    }
    if(eventData){
        body['eventData'] = eventData;
    }
    fetch(EVENTS_API_URL, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const generatePartnerEventCall = (partner: string) => {
    return async (eventType: AnalyticsMethods, eventData?: object) => {
        await callEventsApi(partner, eventType, eventData);
    }
}