import { GF_POPUP_SHOWN_IN_MONTH_EXPIRY, GF_POPUP_SHOWN_IN_WEEK_EXPIRY } from "../utils/constants";

function manageExpiry(key: string, durationInMilliseconds: number) {
    // Get the current timestamp
    const now = new Date().getTime();

    // Check if the key exists in local storage
    const expiryTime = localStorage.getItem(key);

    if (expiryTime === null || !expiryTime) {
        // If the key does not exist, set a new expiry
        setNewExpiry(key, now, durationInMilliseconds);
        return;
    }
    // Parse the stored time
    const expiryTimestamp = parseInt(expiryTime, 10);

    // Check if the stored time is in the past
    if (expiryTimestamp < now) {
        // If in the past, set a new expiry
        setNewExpiry(key, now, durationInMilliseconds);
    }
    // If in the future, do nothing

}

function setNewExpiry(key: string, currentTimestamp: number, durationInMilliseconds: number) {
    // Calculate the new expiry time
    const newExpiryTime = currentTimestamp + durationInMilliseconds;
    // Store the new expiry time in local storage
    localStorage.setItem(key, newExpiryTime.toString());
}

// Define the duration for a week and a month in milliseconds
const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days
const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days, approximate for a month

/**
 * Manage the expiry of the weekly and monthly expiry keys, idempotent
 */
export function manageCookieExpiry() {
    manageExpiry(GF_POPUP_SHOWN_IN_WEEK_EXPIRY, oneWeekInMilliseconds);
    manageExpiry(GF_POPUP_SHOWN_IN_MONTH_EXPIRY, oneMonthInMilliseconds);
}

/**
 * 
 * @returns The time remaining until the weekly expiry
 */
export function getTimeToWeeklyExpiry() {
    const absoluteExpiry= parseInt(localStorage.getItem(GF_POPUP_SHOWN_IN_WEEK_EXPIRY) as string);
    const now = new Date().getTime();
    return absoluteExpiry - now;
}

/**
 * 
 * @returns The time left until the monthly expiry in milliseconds
 */
export function getTimeToMonthlyExpiry() {
    const absoluteExpiry= parseInt(localStorage.getItem(GF_POPUP_SHOWN_IN_MONTH_EXPIRY) as string);
    const now = new Date().getTime();
    return absoluteExpiry - now;
}