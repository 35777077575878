import {
  getAnalytics,
  type AnalyticsUrlsRecord,
  type Analytics
} from "../services/analytics";
import { getConfig } from "../services/resources";
import { renderPopup, setButton, setElement } from "../utils/renders";
import {
  isChromeBased,
  isCorrectPage,
  isFilterBypassed,
  isFirstSessionView,
  isMobile,
  wasNotShowAgainClicked,
  isUsIp,
  wasShownInDay,
  wasShownInWeek,
  wasShownInMonth,
  isInRollOut,
  markVisitor,
  setFirstSessionView
} from "../utils/verifications";

/**
 * CONTENTS is a string that contains the HTML for the popup
 * It is generated by the build process and injected into the bundle
 */
declare const CONTENTS: string;

export const createPopup = async (
  configUrl: string,
  defaultConfig: Config,
  analyticsUrls: AnalyticsUrlsRecord,
  analyticsEventId: string,
  next?: (
    config: Config,
    container: HTMLDivElement,
    analytics: Analytics
  ) => void
) => {
  const analytics = getAnalytics(analyticsUrls, analyticsEventId);

  const addPopup = (contents: string, config: Config) => {
    const bypassFilters = isFilterBypassed();

    // Return if the popup is off
    if (!config.isOn && !bypassFilters) return;

    // Filter by user
    if (!isCorrectPage(config.pageMatch) && !bypassFilters) return;
    if (!isChromeBased() && !bypassFilters) return;
    if (isMobile() && !bypassFilters) return;

    isUsIp().then((isUs) => {
      // Filter by views
      if (!isUs && !bypassFilters) return;
      if (wasNotShowAgainClicked() && !bypassFilters) return;
      if (wasShownInDay() && !bypassFilters) return;
      const shownInWeek = wasShownInWeek() ?? 0;
      if (shownInWeek >= config.repeatInAWeek && !bypassFilters) return;
      const shownInMonth = wasShownInMonth() ?? 0;
      if (shownInMonth >= config.repeatInAMonth && !bypassFilters) return;
      if (!isInRollOut(config.rolloutPercentage) && !bypassFilters) return;

      // Mark visitor after all filters
      markVisitor(config.hoursToShow, shownInWeek, shownInMonth);

      // Render elements
      const container = renderPopup(
        contents,
        "gf-close-btn",
        "gf-block-close-btn",
        analytics
      );
      setButton("gf-open-btn", config.wording.cta, () => {
        window.open(config.installLink, "_blank");
        analytics.onInstallClick();
        container.remove();
      });
      setElement("gf-title", config.wording.title);
      setElement("gf-copy", config.wording.copy);

      // Execute next callback function if it exists
      next?.(config, container, analytics);
    });
  };

  analytics.onLoad();
  const config = await getConfig(configUrl, defaultConfig,analytics);
  const activiationAnalytics = await buildOnActivateData(config);
  analytics.onActivate(activiationAnalytics);
  if (isFirstSessionView()) {
    setFirstSessionView();
    setTimeout(() => {
      addPopup(CONTENTS, config);
    }, config.millisecondsDelayedBeforePop);
  } else {
    addPopup(CONTENTS, config);
  }
};

const buildOnActivateData = async (config: Config) => {
  const isUs = await isUsIp();
  return {
    bypassFilterOn: isFilterBypassed(),
    wasShownInDay: wasShownInDay() ?? false,
    shownInWeek: wasShownInWeek() ?? 0,
    shownInMonth: wasShownInMonth() ?? 0,
    rolloutPercentage: isInRollOut(config.rolloutPercentage),
    millisecondsDelayedBeforePop: config.millisecondsDelayedBeforePop,
    isFirstSessionView: isFirstSessionView(),
    isOn: config.isOn,
    pageMatch: isCorrectPage(config.pageMatch),
    isChromiumBased: isChromeBased(),
    isMobile: isMobile(),
    isUsIp: isUs,
    cookiesEnabled: window.navigator.cookieEnabled,
    isSessionStorageAccessible: !!window.sessionStorage,
    isLocalStorageAccessible: !!window.localStorage
  };
};
