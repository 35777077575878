import { type AnalyticsUrlsRecord } from "../services/analytics";
import { createPopup } from "../utils/popup";

const configUrl = "https://cdn.givefreely.com/popup/ideglobal/config.json";

const defaultConfig: Config = {
  isOn: true,
  hoursToShow: 24,
  repeatInAWeek: 3,
  repeatInAMonth: 6,
  installLink: "https://gvfr.ly/499ajIH",
  questionnaireLink: "",
  millisecondsDelayedBeforePop: 3000,
  rolloutPercentage: 10,
  pageMatch:
    "^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)*ideglobal\\.org(\\/careers)?(?:\\/|\\/?\\?[^ ]*)?$",
  wording: {
    header: "",
    title: "Power entrepreneurs for free by installing Give Freely!",
    copy: "Give Freely is a browser extension that automatically applies coupons at over 10,000 different stores to save you money and the stores donate to iDE with every purchase.  It's free to use, safe and installs quickly.",
    footer: "",
    cta: "Learn more and earn iDE an instant $10 donation",
    openFormBtn: "",
    closeBtn: "",
    notShowBtn: ""
  }
};

const analyticsUrls: AnalyticsUrlsRecord = {
  onClose: "https://gvfr.ly/3TNpZwG",
  onOpen: "https://gvfr.ly/3VzLbYA",
  onFailToLoadConfig: "https://gvfr.ly/3vi7reP",
  onLoad: "https://gvfr.ly/3x1CcVB",
  onActivate: "",
  onInstallClick: ""
};

const analyticsEventId = "ideglobal";

createPopup(configUrl, defaultConfig, analyticsUrls, analyticsEventId);
